define('dashboard/components/project-page', ['exports', 'dashboard/mixins/sorting', 'dashboard/mixins/project-actions', 'dashboard/mixins/tagging'], function (exports, _sorting, _projectActions, _tagging) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_sorting.default, _projectActions.default, _tagging.default, {
    isDeleteProjectModalHidden: true,
    isMoveProjectModalHidden: true,
    hideCreateProjectModal: true,
    allProjectsPage: false,
    projectToMove: null,

    sortField: 'lastUpdatedScenario',
    sortDir: 'desc',
    // classNames: "container",

    init: function init() {
      this._super.apply(this, arguments);
      this.set('selectedTags', this.get('selectedTags') || []);
      this.set('moveProjectOptions', ['Active', 'Won', 'Lost', 'Archived']);

      this.set('projectRowOptions', {
        title: 'Project',
        options: [{ icon: 'fas fa-pencil fa-lg', text: 'Rename', action: 'toggleRenameProject' }, { icon: 'fas fa-arrow-alt-square-right fa-lg', text: 'Change Status', action: 'openMoveProjectModal' }, { delimiter: true }, { icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'openDeleteProjectModal', color: 'red' }]
      });
    },


    clientTags: Ember.computed.alias('projectsController.model.tags'),

    sortColumns: Ember.computed('projectsController.sortColumns', 'allProjectsPage', function () {
      var newColumns = this.get('projectsController.sortColumns').toArray();
      if (this.get('allProjectsPage')) {
        newColumns.unshift({});
      }
      return newColumns;
    }),

    tagWatcher: Ember.observer('tagToAdd', function () {
      this.send('addTagToFilterTagsList', this.get('tagToAdd'));
    }),

    projectList: Ember.computed('projectListProperty', 'projectsWon.[]', 'projectsLost.[]', 'allProjects.[]', 'archivedProjects.[]', 'activeProjects.[]', function () {
      return this.get(this.get('projectListProperty'));
    }),

    allProjects: Ember.computed.alias('filteredList'),

    projectsWon: Ember.computed.filterBy('filteredList', 'won'),
    projectsLost: Ember.computed.filterBy('filteredList', 'lost'),
    archivedProjects: Ember.computed.filterBy('filteredList', 'archived'),
    activeProjects: Ember.computed.filterBy('filteredList', 'active')

  });
});