define('dashboard/components/file-upload-table-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'tr',
    classNames: ['cursor', 'project-document-row', 'clickable'],
    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('fileUploadTableRowOptions', {
        title: 'Document',
        options: [{ icon: 'fas fa-pencil fa-lg', text: 'Rename', action: 'editDocumentName' }, { delimiter: true }, { icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'deleteDocumentHandler', color: 'red' }]

      });
    },


    canModifyFiles: true,
    editing: false,
    isFileUploadTableRowOptionsHidden: true,

    click: function click() {
      this.sendAction('onClick', this.get('document'));
    },


    actions: {
      editDocumentName: function editDocumentName() {
        this.set('editing', true);
      },
      deleteDocumentHandler: function deleteDocumentHandler() {
        this.sendAction('deleteDocumentHandler', this.get('document'));
      },
      updateName: function updateName() {
        var _this = this;

        var document = this.get('document');
        document.save().then(function () {
          _this.set('editing', false);
        }).catch(function () {
          _this.set('editing', false);
          document.rollbackAttributes();
          _this.get('notify').alert('There was an error.  Double-check the new name for issues or try again later.');
        });
      }
    }

  });
});