define('dashboard/controllers/data-requests/pdf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    wizardSections: Ember.computed('model.wizardSections', function () {
      return this.get('model.wizardSections').rejectBy('division', 'plans');
    }),

    actions: {
      print: function print() {
        window.print();
      },
      noop: function noop() {}
    }

  });
});