define('dashboard/authenticators/custom', ['exports', 'ember-simple-auth/authenticators/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({

    ajax: Ember.inject.service(),

    restore: function restore(data) {
      return new Ember.RSVP.Promise(function (resolve, reject) {
        if (!Ember.isEmpty(data.token)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    authenticate: function authenticate(creds) {

      var ajax = this.get('ajax');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        ajax.post('login', {
          data: JSON.stringify({
            login: creds.identification,
            password: creds.password
          }),
          contentType: 'application/json'
        }).then(function (response) {
          Ember.run(function () {
            resolve({ token: response.login.token });
          });
        }).catch(function (error) {
          // Return error as XHR object
          Ember.run(function () {
            console.log('reject', 'error:', error);
            reject(error);
          });
        });
      });
    }

  });
});