define('dashboard/controllers/data-forms/inbox/index', ['exports', 'ember-cli-pagination/computed/paged-array', 'dashboard/mixins/sorting', 'dashboard/mixins/scenario-actions', 'dashboard/mixins/read-only-restrictions'], function (exports, _pagedArray, _sorting, _scenarioActions, _readOnlyRestrictions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_sorting.default, _scenarioActions.default, _readOnlyRestrictions.default, {

    router: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.computed.alias('session.currentUser'),

    queryParams: ['selectedTab'],
    client: Ember.computed.alias('model.client'),
    inboxItems: Ember.computed.filterBy('model.scenarios', 'isRequest'),
    sortedList: Ember.computed.sort('inboxItems', 'sortedValue'),
    isDeleteScenarioModalHidden: true,
    isRestoreScenarioModalHidden: true,
    isMoveProjectModalHidden: true,
    isShareDataRequestModalHidden: true,
    isCreateProjectModalHidden: true,
    readOnlyRestrictedModalHidden: true,
    isRevisingDataRequest: false,
    newProjectTitle: null,

    sortField: 'created',
    sortDir: 'desc',

    selectedTab: 'inbox',

    displayedList: Ember.computed('filteredList.@each.{inboxScreen,bestGuessEmail,deletedDataForm}', 'selectedTab', function () {
      var selectedTab = this.get('selectedTab');
      return this.get('filteredList').filter(function (dr) {
        var correctTab = dr.get("inboxScreen") == selectedTab && dr.get('bestGuessEmail');
        return correctTab;
      });
    }),

    pagedContent: (0, _pagedArray.default)('displayedList', { infinite: "unpaged", perPage: 20 }),

    columnsHash: Ember.computed(function () {
      return {
        dateSubmitted: { title: 'Date Submitted', sortField: 'submittedOn' },
        prospectEmail: { title: 'Prospect Email', sortField: 'bestGuessEmail' },
        dateStarted: { title: 'Date Started', sortField: 'created' },
        lastUpdated: { title: 'Last Updated', sortField: 'lastUpdated' },
        percentComplete: { title: '% Complete', sortField: 'percentComplete' },
        projectTitle: { title: 'Project Name', sortField: 'project.title' },
        actions: { title: '', textAlign: 'right' }
      };
    }),

    tabSelection: Ember.computed('selectedTab', function () {
      var tabName = this.get('selectedTab');
      var tabSelections = {
        inbox: ['dateSubmitted', 'prospectEmail', 'actions'],
        'in-progress': ['dateStarted', 'lastUpdated', 'prospectEmail', 'percentComplete', 'actions'],
        assigned: ['dateSubmitted', 'prospectEmail', 'projectTitle', 'actions'],
        archived: ['dateStarted', 'dateSubmitted', 'lastUpdated', 'prospectEmail', 'percentComplete', 'actions']
      };
      return tabSelections[tabName];
    }),

    sortColumns: Ember.computed('tabSelection', function () {
      var columnsHash = this.get('columnsHash');
      var tabSelection = this.get('tabSelection');
      var indexHeader = [{ title: '' }];
      return indexHeader.concat(tabSelection.map(function (t) {
        return columnsHash[t];
      }));
    }),

    actions: {
      openMoveProjectsModal: function openMoveProjectsModal(scenario) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedScenario', scenario);
        this.set('isCreateProjectModalHidden', true);
        this.set('isMoveProjectModalHidden', false);
      },
      openCreateProjectModal: function openCreateProjectModal(scenario) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedScenario', scenario);
        this.set('isMoveProjectModalHidden', true);
        this.set('isCreateProjectModalHidden', false);
      },
      showDeleteModal: function showDeleteModal(scenario) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedScenario', scenario);
        this.set('isDeleteScenarioModalHidden', false);
      },
      showRestoreModal: function showRestoreModal(scenario) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedScenario', scenario);
        this.set('isRestoreScenarioModalHidden', false);
      },


      // would previously destroy scenario record, now we're going to archive
      deleteScenario: function deleteScenario(scenario) {
        scenario.set('deletedDataForm', true);
        scenario.save();
      },
      restoreScenario: function restoreScenario(scenario) {
        if (!this.get('currentUser.isSuperAdmin')) {
          return;
        }
        scenario.set('deletedDataForm', false);
        scenario.save();
      },
      updateFiltering: function updateFiltering(filterType, option) {
        this.set(filterType, option.uniqueId);
      },
      goToRequest: function goToRequest(dataRequest) {
        // don't let read-only clients view new data forms
        var readOnly = this.get('client.readOnlyAccess');
        var isNew = dataRequest.get('displayAsUnread');
        var isSuperAdmin = this.get('currentUser.isSuperAdmin');
        if (readOnly && isNew && !isSuperAdmin) {
          this.set('readOnlyRestrictedModalHidden', false);
          return;
        }

        this.transitionToRoute('data-forms.inbox.data-request', dataRequest.id);
      },
      goToProject: function goToProject(client, project) {
        this.transitionToRoute('project', client.id, project.id);
      },
      openShareRequestModal: function openShareRequestModal(scenario) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedScenario', scenario);
        this.set('isRevisingDataRequest', false);
        this.set('isShareDataRequestModalHidden', false);
      },
      openReviseRequestModal: function openReviseRequestModal(scenario) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('selectedScenario', scenario);
        this.set('isRevisingDataRequest', true);
        this.set('isShareDataRequestModalHidden', false);
      },


      /*
        Handles follow-up actions after creating a new project with a submitted data form
        @param {Object} project the project we just created for the data form
        @param {Object} scenario the data form we're moving
        @param {Boolean} newScenario tells us if we're creating a new scenario with this project
      */
      afterProjectCreate: function afterProjectCreate(project, scenario, newScenario) {
        // push new project into projects array
        this.get('client.projects').pushObject(project);
        this.send('requestMoveFinal', project, scenario, newScenario);
      }
    }
  });
});