define('dashboard/controllers/data-forms/request-templates/request-template', ['exports', 'dashboard/mixins/request-template-actions', 'dashboard/mixins/read-only-restrictions'], function (exports, _requestTemplateActions, _readOnlyRestrictions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_requestTemplateActions.default, _readOnlyRestrictions.default, {
    isDeleteTemplateModalHidden: true,
    isShareModalHidden: true,
    isRenaming: false,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('requestTemplateOptions', {
        title: 'Request Template',
        options: [{ icon: 'fas fa-pencil fa-lg', text: 'Rename', action: 'rename' }, { icon: 'fas fa-clone fa-lg', text: 'Copy', action: 'copy' }, { delimiter: true }, { icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'delete', color: 'red' }]
      });
    },


    isRequestTemplateOptionsHidden: true,

    requestTemplate: Ember.computed.alias('model'),
    client: Ember.computed.alias('model.client'),

    showPDFOption: Ember.observer('isPdfPage', function () {
      if (this.get('isPdfPage')) {
        this.get('requestTemplateOptions.options').unshift({ icon: 'fas fa-file-pdf fa-lg', text: 'Save PDF', action: 'print' });
      } else {
        this.set('requestTemplateOptions.options', [{ icon: 'fas fa-pencil fa-lg', text: 'Rename', action: 'rename' }, { icon: 'fas fa-clone fa-lg', text: 'Copy', action: 'copy' }, { delimiter: true }, { icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'delete', color: 'red' }]);
      }
    }),

    preCopyStep: function preCopyStep() {
      this.set('isLoading', true);
    },
    postCopyStep: function postCopyStep(copy) {
      Ember.run.later(this, function () {
        this.transitionToRoute('data-forms.request-templates.request-template.edit', copy.get('id'));
        this.set('isLoading', false);
      }, 1500);
    },


    breadcrumbItems: Ember.computed('model.title', function () {
      var items = [{
        label: 'Data Forms',
        params: ['data-forms.inbox']
      }, {
        label: 'Templates',
        params: ['data-forms.request-templates'],
        dataTest: 'back-to-template-index'
      }];

      items.push({ label: this.get('model.title'), active: true });

      return items;
    }),

    isPdfPage: Ember.computed.equal('target.currentRouteName', 'data-forms.request-templates.request-template.pdf'),

    actions: {
      openShareDataRequestModal: function openShareDataRequestModal() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isShareModalHidden', false);
      },
      updateTitle: function updateTitle(title) {
        if (!title) {
          return;
        }
        this.set('model.title', title);
        this.get('model').save();
      },
      delete: function _delete() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isDeleteTemplateModalHidden', false);
      },
      rename: function rename() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set("isRenaming", true);
      },
      deleteRequestTemplate: function deleteRequestTemplate() {
        var _this = this;

        this.set('isDeleteTemplateModalHidden', true);
        var requestTemplate = this.get('model');
        requestTemplate.set('deleted', true);
        requestTemplate.save().then(function () {
          _this.transitionToRoute('data-forms.request-templates');
        });
      },
      print: function print() {
        window.print();
      }
    }

  });
});