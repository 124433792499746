define('dashboard/models/project', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships'], function (exports, _model, _attr, _relationships) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('pending', []);
    },


    client: (0, _relationships.belongsTo)('client'),
    scenarios: (0, _relationships.hasMany)('scenario'),
    tags: (0, _relationships.hasMany)('tag'),
    fileUploads: (0, _relationships.hasMany)(),

    title: (0, _attr.default)('string'),
    isHidden: (0, _attr.default)('boolean'),
    isWon: (0, _attr.default)('boolean'),
    isLost: (0, _attr.default)('boolean'),
    finalizedScenario: (0, _attr.default)('string'),
    finalizedScenarioIsEconomistReviewed: (0, _attr.default)('boolean'),
    lastUpdated: (0, _attr.default)('date'),
    created: (0, _attr.default)('date'),

    projectType: (0, _relationships.belongsTo)('tag'),
    industry: (0, _relationships.belongsTo)('tag'),

    dataRequests: Ember.computed.filterBy('scenarios', 'isRequest'),

    active: Ember.computed('isHidden', 'isWon', 'isLost', function () {
      return !this.get('isHidden') && !this.get('isWon') && !this.get('isLost');
    }),

    won: Ember.computed('isHidden', 'isWon', 'isLost', function () {
      return !this.get('isHidden') && this.get('isWon') && !this.get('isLost');
    }),

    lost: Ember.computed('isHidden', 'isWon', 'isLost', function () {
      return !this.get('isHidden') && !this.get('isWon') && this.get('isLost');
    }),

    archived: Ember.computed('isHidden', 'isWon', 'isLost', function () {
      return this.get('isHidden') && !this.get('isWon') && !this.get('isLost');
    }),

    indexedScenariosOnly: Ember.computed('scenarios.@each.{isRequest,isDeleted,created}', function () {
      var scenarios = this.get('scenarios').sortBy('created') || [];
      var index = 1;
      return scenarios.filter(function (scenario) {
        if (scenario.get('isRequest') || scenario.get('isDeleted')) {
          return false;
        }
        scenario.set('index', index++);
        return true;
      });
    }),

    lastUpdatedScenario: Ember.computed('indexedScenariosOnly.@each.lastUpdated', function () {
      return this.get('indexedScenariosOnly').sortBy('lastUpdated').get('lastObject.lastUpdated') || this.get('created');
    }),

    lastUpdatedScenarioModel: Ember.computed('indexedScenariosOnly.@each.lastUpdated', function () {
      return this.get('indexedScenariosOnly').sortBy('lastUpdated').get('lastObject') || this.get('indexedScenariosOnly').sortBy('created').get('lastObject');
    }),

    activeScenarioId: (0, _attr.default)('string'),

    currentlyActiveScenario: Ember.computed('activeScenarioId', 'lastUpdatedScenarioModel', function () {
      var activeScenarioId = this.get('activeScenarioId');

      if (activeScenarioId) {
        return activeScenarioId;
      } else if (this.get('lastUpdatedScenarioModel')) {
        return this.get('lastUpdatedScenarioModel.id');
      } else {
        return null;
      }
    }),

    bucket: Ember.computed('isWon', 'isLost', 'isHidden', function () {
      var bucket = 'index';

      if (this.get('isWon')) {
        bucket = 'won';
      } else if (this.get('isLost')) {
        bucket = 'lost';
      } else if (this.get('isHidden')) {
        bucket = 'archived';
      }

      return bucket;
    }),

    projectStatus: Ember.computed('won', 'lost', 'archived', 'active', function () {
      var projectWon = this.get('won');
      var projectLost = this.get('lost');
      var projectArchived = this.get('archived');
      var projectActive = this.get('active');

      if (projectWon) {
        return 'Won';
      }

      if (projectLost) {
        return 'Lost';
      }

      if (projectArchived) {
        return 'Archived';
      }

      if (projectActive) {
        return 'Active';
      }
    })

  });
});