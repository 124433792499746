define('dashboard/components/modals/data-request-create-project-modal', ['exports', 'dashboard/mixins/project-actions'], function (exports, _projectActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_projectActions.default, {

    createScenarioWithProject: true,

    confirmText: Ember.computed('createScenarioWithProject', function () {
      return this.get('createScenarioWithProject') ? 'Create Project & Scenario' : 'Create Project';
    }),

    actions: {
      openMoveProjectsModal: function openMoveProjectsModal() {
        this.sendAction('openMoveProjectsModal', this.get('scenario'));
      },
      openDisassociateFromProjectModal: function openDisassociateFromProjectModal() {
        this.sendAction('openDisassociateFromProjectModal', this.get('scenario'));
      },
      afterProjectCreate: function afterProjectCreate(project, scenario) {
        var newScenario = this.get('createScenarioWithProject');
        this.set('isHidden', true);
        this.sendAction('afterProjectCreateAction', project, scenario, newScenario);
      },
      cancel: function cancel() {
        this.set('newProjectTitle', '');
        this.set('createScenarioWithProject', true);
      }
    }

  });
});