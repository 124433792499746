define('dashboard/models/client', ['exports', 'ember-data/model', 'ember-data/attr', 'ember-data/relationships', 'dashboard/fixtures/state-tax-profiles', 'ember-cp-validations'], function (exports, _model, _attr, _relationships, _stateTaxProfiles, _emberCpValidations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    name: [(0, _emberCpValidations.validator)('presence', { description: 'Organization Name', presence: true, ignoreBlank: true })]
  });

  exports.default = _model.default.extend(Validations, {
    init: function init() {
      this._super.apply(this, arguments);

      this.set('userSortKey', ['created']);
    },


    districtGroups: (0, _relationships.hasMany)('districtGroup'),
    projects: (0, _relationships.hasMany)('project'),
    requestTemplates: (0, _relationships.hasMany)(),
    scenarios: (0, _relationships.hasMany)(),
    assumption: (0, _relationships.belongsTo)('defaultAssumption'),
    users: (0, _relationships.hasMany)('users'),
    fileUploads: (0, _relationships.hasMany)('fileUploads'),
    subscription: (0, _relationships.belongsTo)('subscription'),
    logo: (0, _relationships.belongsTo)('file-upload'),
    userGuide: (0, _relationships.belongsTo)('file-upload'),
    optionalGroups: (0, _relationships.hasMany)('districtGroup', { inverse: null }),

    name: (0, _attr.default)('string'),
    phone: (0, _attr.default)('string'),
    address1: (0, _attr.default)('string'),
    address2: (0, _attr.default)('string'),
    city: (0, _attr.default)('string'),
    state: (0, _attr.default)('string'),
    zip: (0, _attr.default)('string'),
    readOnlyAccess: (0, _attr.default)('boolean', { defaultValue: false }),
    created: (0, _attr.default)('date'),
    districtConfigType: (0, _attr.default)('string'),
    lastDistrictTransition: (0, _attr.default)('date'),
    colorTheme: (0, _attr.default)('string', { defaultValue: 'Blue' }),

    squareFootageTypes: (0, _attr.default)(),

    // Essentials clients have limited access to certain sections of the app
    essentialsClient: (0, _attr.default)('boolean', { defaultValue: false }),

    mostRecentInboxVisit: (0, _attr.default)('date'),
    activated: (0, _attr.default)('boolean', { defaultValue: false }),
    blocked: (0, _attr.default)('boolean', { defaultValue: false }),

    logoUrl: Ember.computed.alias('logo.url'),
    userGuideUrl: Ember.computed.alias('userGuide.url'),

    isReady: Ember.computed.gt('districtGroups.length', 0),
    notActivated: Ember.computed.not('activated'),

    scenarioCount: Ember.computed('projects.@each.indexedScenariosOnly', function () {
      var projects = this.get('projects'),
          count = 0;
      projects.forEach(function (project) {
        count += project.get('indexedScenariosOnly.length');
      });
      return count;
    }),

    projectCount: Ember.computed.reads('projects.length'),

    lastActive: Ember.computed('users.@each.lastActive', function () {
      var clientLastActive = null;

      this.get('users').forEach(function (user) {
        var userLastActive = user.get('lastActive');
        if (userLastActive > clientLastActive) clientLastActive = userLastActive;
      });
      return clientLastActive;
    }),

    lastLogin: Ember.computed('users.@each.lastLogin', function () {
      var clientLastLogin = null;

      this.get('users').forEach(function (user) {
        var userLastLogin = user.get('lastLogin');
        if (userLastLogin > clientLastLogin) clientLastLogin = userLastLogin;
      });
      return clientLastLogin;
    }),

    sortedUsers: Ember.computed.sort('users', 'userSortKey'),

    cityStateZip: Ember.computed('city', 'state', 'zip', function () {
      var _getProperties = this.getProperties('city', 'state', 'zip'),
          city = _getProperties.city,
          state = _getProperties.state,
          zip = _getProperties.zip;

      if (city && state) {
        var parts = [city, state];
        if (zip) parts.push(zip);
        return parts.join(', ');
      }
    }),

    defaultScenarioRequestNote: Ember.computed('fullName', 'name', 'phone', 'user.email', function () {
      var fullName = this.get('fullName') ? this.get('fullName') + "\n" : "",
          name = this.get('name') ? this.get('name') + " \n" : '',
          phone = this.get('phone') ? this.get('phone') + " \n" : '',
          email = this.get('user.email') ? this.get('user.email') : '';

      return "\nGreetings! \n\n" + "Please provide information on your project using the data form below and contact me if you have any questions. \n\n" + fullName + name + phone + email;
    }),

    usesStandardDistrictConfig: Ember.computed('districtConfigType', function () {
      return !this.get('districtConfigType') || this.get('districtConfigType') === 'standard';
    }),

    propertyTaxRateCap: Ember.computed.equal('state', 'Indiana'),

    stateTaxProfile: Ember.computed('state', function () {
      var state = this.get('state');
      if (state) {
        return _stateTaxProfiles.default[state];
      } else {
        return {
          code: 'DEFAULT',
          land: true,
          buildings: true,
          ffe: true,
          inventory: true,
          salesTaxForLocalClient: true,
          salesTaxForStateClient: true
        };
      }
    }),

    clientLevelOptions: Ember.computed(function () {
      return [{ value: 'full', label: 'Impact DashBoard Pro', shortLabel: 'Pro' }, { value: 'essentials', label: 'Impact DashBoard Essentials', shortLabel: 'Essentials' }, { value: 'readOnly', label: 'Expired - Read Only', shortLabel: 'Read Only' }];
    }),

    selectedClientLevel: Ember.computed('readOnlyAccess', 'essentialsClient', function () {
      // order is intentional here since multiple statuses can be true, we want to show the "most severe" one first
      var status = this.get('essentialsClient') ? 'essentials' : 'full';

      if (this.get('readOnlyAccess')) {
        status = 'readOnly';
      }

      return this.get('clientLevelOptions').findBy('value', status);
    }),

    updateClientStatus: function updateClientStatus(status) {
      // we want to keep the essentials level even if read only is selected
      switch (status) {
        case 'full':
          this.set('readOnlyAccess', false);
          this.set('essentialsClient', false);
          break;
        case 'essentials':
          this.set('readOnlyAccess', false);
          this.set('essentialsClient', true);
          break;
        case 'readOnly':
          this.set('readOnlyAccess', true);
          break;
      }
    },


    firstUser: Ember.computed.alias('users.firstObject'),

    adminUsers: Ember.computed.filterBy('sortedUsers', 'role', 'admin'),
    activeUsers: Ember.computed.filterBy('sortedUsers', 'archived', false),
    activeAdminUsers: Ember.computed.filterBy('adminUsers', 'archived', false),
    hasActiveAdminUsers: Ember.computed.gt('activeAdminUsers.length', 0),
    archivedUsers: Ember.computed.filterBy('sortedUsers', 'archived', true),
    hasArchivedUsers: Ember.computed.gt('archivedUsers.length', 0),

    invalidUsers: Ember.computed.filterBy('sortedUsers', 'isInvalid', true),
    hasInvalidUsers: Ember.computed.gt('invalidUsers.length', 0),

    hasBeenAccessed: Ember.computed.bool('lastActive'),

    isActivatable: Ember.computed('isReady', 'notActivated', 'hasActiveAdminUsers', 'hasBeenAccessed', function () {
      var _getProperties2 = this.getProperties('isReady', 'notActivated', 'hasActiveAdminUsers', 'hasBeenAccessed'),
          isReady = _getProperties2.isReady,
          notActivated = _getProperties2.notActivated,
          hasActiveAdminUsers = _getProperties2.hasActiveAdminUsers,
          hasBeenAccessed = _getProperties2.hasBeenAccessed;

      if (!isReady) return false;
      if (!hasActiveAdminUsers) return false;
      if (notActivated || !hasBeenAccessed) return true;

      return false;
    }),

    isValid: Ember.computed.alias('validations.isValid'),
    isInvalid: Ember.computed.alias('validations.isInvalid')
  });
});