define('dashboard/components/user-row', ['exports', 'dashboard/mixins/hover', 'dashboard/mixins/project-actions', 'dashboard/mixins/tagging'], function (exports, _hover, _projectActions, _tagging) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tagging.default, _projectActions.default, _hover.default, {
    classNames: ['user-row', 'clickable'],
    tagName: 'tr',

    notify: Ember.inject.service(),
    session: Ember.inject.service(),

    click: function click() {
      this.send('edit');
    },


    /**
     * By default, displaying a user-row on a table should disable the ability to manage users.
     * Rather than accessing the full currentUser scope, better to pass in this boolean value when
     * rendering this component.
     */
    canManageUsers: false,
    isUserRowOptionsHidden: true,

    currentUser: Ember.computed.alias('session.currentUser'),

    isCurrentUser: Ember.computed('user', 'currentUser', function () {
      return this.get('user.id') === this.get('currentUser.id');
    }),

    isHovering: Ember.computed.or('hover', 'popoverOpen'),

    userRowOptions: Ember.computed('user.{archived}', function () {
      var output = {
        title: 'User',
        options: [{ icon: 'fas fa-pencil fa-lg', text: 'Edit User', action: 'edit' }, { icon: 'fas fa-key fa-lg', text: 'Reset Password', action: 'resetPassword' }, { delimiter: true }]
      };

      if (this.get('user.archived')) {
        output.options.push({ icon: 'fas fa-user-times fa-lg', text: 'Restore User', action: 'restore' });
      } else {
        output.options.push({ icon: 'fas fa-user-times fa-lg', text: 'Remove User', action: 'archive', color: 'red' });
      }

      return output;
    }),

    enableEditAction: Ember.computed('isCurrentUser', function () {
      return this.get('isCurrentUser') || this.get('canManageUsers');
    }),

    enableRemoveAction: Ember.computed('isCurrentUser', function () {
      if (this.get('isCurrentUser')) return false;
      return this.get('canManageUsers');
    }),

    actions: {
      save: function save() {
        var _this = this;

        var user = this.get('user');
        user.save().then(function () {
          return _this.get('notify').success(user.get('fullName') + ' updated');
        }).catch(function () {
          return _this.get('notify').alert('A problem occurred while trying to update ' + user.get('fullName') + '\'s account.');
        });
      },
      edit: function edit() {
        this.sendAction('editUserAction', this.get('user'));
      },
      archive: function archive() {
        this.sendAction('changeUserArchivedAction', this.get('user'));
      },
      restore: function restore() {
        this.sendAction('changeUserRestoreAction', this.get('user'));
      },
      resetPassword: function resetPassword() {
        this.sendAction('resetUserPasswordAction', this.get('user'));
      }
    }
  });
});