define('dashboard/components/data-form-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {
    tagName: 'tr',
    classNames: ['clickable'],
    classNameBindings: ['isNew:bold'],
    attributeBindings: ['data-test'],

    init: function init() {
      this._super.apply(this, arguments);

      this.set('assignProjectOptions', [Ember.Object.create({
        label: 'Create New Project',
        action: 'openCreateProjectModal'
      }), Ember.Object.create({
        label: 'Assign to Existing Project',
        action: 'openMoveProjectsModal'
      })]);
    },


    isDataFormRowOptionsHidden: true,

    isNew: Ember.computed.alias('scenario.displayAsUnread'),

    dataFormRowOptions: Ember.computed('scenario.{project}', 'selectedTab', function () {
      var selectedTab = this.get('selectedTab');
      var rowOptions = { title: 'Data Form', options: [] };

      // Assign to Project Options
      if (selectedTab === 'inbox') {}
      // rowOptions.options.push({ icon: 'fas fa-external-link-alt fa-lg', text: 'Create New Project', action: 'openCreateProjectModal' });
      // rowOptions.options.push({ icon: 'fas fa-external-link-alt fa-lg', text: 'Assign to Existing Project', action: 'openMoveProjectsModal' });
      // rowOptions.options.push({ icon: 'fas fa-external-link-alt fa-lg', text: 'Assign to Project', action: 'openMoveProjectsModal' });


      // Share Data Forn
      if (selectedTab === 'in-progress') {
        rowOptions.options.push({ icon: 'fas fa-share fa-lg', text: 'Share', action: 'openShareRequestModal' });
      }

      // Go to Project
      if (this.get('scenario.project.id')) {
        rowOptions.options.push({ icon: 'fas fa-arrow-square-right fa-lg', text: 'Go to Project', action: 'goToProject' });
      }

      // Allow Revision
      if (selectedTab === 'inbox' || selectedTab === 'assigned') {
        rowOptions.options.push({ icon: 'fa-solid fa-pen-to-square fa-lg', text: 'Allow Revision', action: 'openReviseRequestModal' });
      }

      // Delete Data Form
      if (!(selectedTab === 'assigned' || selectedTab === 'archived')) {
        rowOptions.options.push({ delimiter: true });
        rowOptions.options.push({ icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'showDeleteModal', color: 'red' });
      }

      // Restore Data Form (admin only)
      if (selectedTab === 'archived') {
        // rowOptions.options.push({ delimiter: true });
        rowOptions.options.push({ icon: 'fas fa-trash-undo fa-lg', text: 'Restore', action: 'showRestoreModal', color: 'red' });
      }

      return rowOptions;
    }),

    click: function click() {
      this.send('goToRequest', this.get('scenario'));
    },


    actions: {
      goToRequest: function goToRequest(dataRequest) {
        this.sendAction('goToRequest', dataRequest);
      },
      goToProject: function goToProject(client, project) {
        this.sendAction('goToProject', client, project);
      },
      openCreateProjectModal: function openCreateProjectModal() {
        var scenario = this.get('scenario');
        this.sendAction('openCreateProjectModal', scenario);
      },
      openMoveProjectsModal: function openMoveProjectsModal() {
        this.sendAction('openMoveProjectsModal', this.get('scenario'));
      },
      openShareRequestModal: function openShareRequestModal(scenario) {
        this.sendAction('openShareRequestModal', scenario);
      },
      openReviseRequestModal: function openReviseRequestModal(scenario) {
        this.sendAction('openReviseRequestModal', scenario);
      },
      showDeleteModal: function showDeleteModal(scenario) {
        this.sendAction('showDeleteModal', scenario);
      },
      showRestoreModal: function showRestoreModal(scenario) {
        this.sendAction('showRestoreModal', scenario);
      }
    }
  });
});