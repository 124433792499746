define('dashboard/routes/data-requests/pdf', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('requestTemplate', params.request_template_id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var client = model.get('client');
      var scenario = this.get('store').createRecord('scenario', {
        requestTemplate: model,
        // defaulting to the types that will show the most, since data won't be saved between reloads
        businessType: 'existing',
        client: model.get('client'),
        willExpand: true
      });

      controller.setProperties({ client: client, scenario: scenario });
    }
  });
});