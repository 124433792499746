define('dashboard/components/tag-row', ['exports', 'dashboard/mixins/hover'], function (exports, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {

    tagName: 'tr',
    classNames: ['clickable'],

    init: function init() {
      this._super.apply(this, arguments);

      this.set('tagRowOptions', {
        title: 'Tag',
        options: [{ icon: 'fas fa-pencil fa-lg', text: 'Rename', action: 'toggleRenaming' }, { delimiter: true }, { icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'openDeleteTag', color: 'red' }]
      });
    },


    isTagRowOptionsHidden: true,

    click: function click() {
      this.sendAction('transitionToFilteredProjects', this.get('tag'));
    },


    actions: {
      toggleRenaming: function toggleRenaming() {
        this.set('editing', true);
      },
      openDeleteTag: function openDeleteTag() {
        var tag = this.get('tag');
        this.sendAction('openDeleteTag', tag);
      },
      update: function update() {
        var tag = this.get('tag.tag');
        tag.save();
      }
    }
  });
});