define('dashboard/components/request-template-row', ['exports', 'dashboard/mixins/request-template-actions'], function (exports, _requestTemplateActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_requestTemplateActions.default, {
    tagName: '',

    isRequestTemplateOptionsHidden: true,

    client: Ember.computed.alias('requestTemplate.client'),

    actions: {
      delete: function _delete() {
        this.openDeleteModal(this.get('requestTemplate'));
      },
      showAnonymousLink: function showAnonymousLink() {
        this.openLinkModal(this.get('requestTemplate'));
      }
    }
  });
});