define('dashboard/components/modals/scenario-move-modal', ['exports', 'dashboard/components/modals/modal-confirm'], function (exports, _modalConfirm) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _modalConfirm.default.extend({
    selectedProject: null,

    // TODO filter out currently assigned project
    sortedProjects: Ember.computed('projects.@each.{active,won,lost,archived}', 'scenario.project', function () {
      var currentProject = this.get('scenario.project.content');
      var projects = this.get('projects').without(currentProject),
          statuses = ['active', 'won', 'lost', 'archived'];
      var output = [];

      statuses.forEach(function (status) {
        var groupName = status.capitalize();
        var options = projects.filterBy(status).sortBy('lastUpdatedScenario').reverse();
        if (options.get('length')) {
          output.push({ groupName: groupName, options: options });
        }
      });

      return output;
    }),

    actions: {
      selectProject: function selectProject(project) {
        this.set('selectedProject', project);
      },
      move: function move() {
        this.moveScenario(this.get('scenario'), this.get('selectedProject'));
        this.send('slideOut');
      },
      cancel: function cancel() {
        this.set('selectedProject', null);
        this.send('slideOut');
      },
      openCreateProjectModal: function openCreateProjectModal() {
        this.openCreateProjectModal(this.get('scenario'));
        this.send('cancel');
      }
    }
  });
});