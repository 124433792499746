define('dashboard/components/fa-menu-popover-scenario-row', ['exports', 'dashboard/components/fa-menu-popover'], function (exports, _faMenuPopover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _faMenuPopover.default.extend({
    bindScrolling: function bindScrolling() {
      var _this = this;

      var trackScroll = function trackScroll() {
        return _this.scrolled();
      };
      var onScroll = function onScroll() {
        Ember.run.debounce(_this, trackScroll, 50);
      };

      Ember.$('.scenario-list-card tbody').bind('touchmove', onScroll);
      Ember.$('.scenario-list-card tbody').bind('scroll', onScroll);
    },
    unbindScrolling: function unbindScrolling() {
      Ember.$('.scenario-list-card tbody').unbind('touchmove');
      Ember.$('.scenario-list-card tbody').unbind('scroll');
    },
    scrolled: function scrolled() {
      var innerContent = document.getElementsByClassName('scenario-list-card-table-body')[0];
      var offsetTop = Math.floor(innerContent.scrollTop);

      this.set('offsetTop', offsetTop);
    }
  });
});