define('dashboard/mixins/request-template-actions', ['exports', 'dashboard/mixins/read-only-restrictions'], function (exports, _readOnlyRestrictions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create(_readOnlyRestrictions.default, {
    isRenaming: false,
    isShowingOptions: false,
    notify: Ember.inject.service(),

    init: function init() {
      this._super.apply(this, arguments);
      this.set('additionalActions', [Ember.Object.create({
        label: 'Rename',
        action: 'rename'
      }), Ember.Object.create({
        label: 'Copy',
        action: 'copy'
      }), Ember.Object.create({
        label: 'Delete',
        action: 'delete'
      })]);
    },


    actions: {
      selectOption: function selectOption(option) {
        this.send(option);
      },
      rename: function rename() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isRenaming', true);
        this.set('isShowingOptions', false);
      },
      copy: function copy() {
        var _this = this;

        if (this.checkReadOnly()) {
          return;
        }
        if (this.get('preCopyStep')) {
          this.preCopyStep();
        }

        this.get('requestTemplate').copy(true, {
          overwrite: {
            title: 'Copy of ' + this.get('requestTemplate.title')
          }
        }).then(function (copy) {
          return copy.save();
        }).then(function (copy) {
          _this.get('notify').info('Successfully copied ' + copy.get('title'));
          _this.postCopyStep(copy);
        });
      },
      delete: function _delete() {
        console.log('hitting this in request-template-actions');
        // overwrite this in the controller/component where the mixin is used
      },
      updateTitle: function updateTitle() {
        this.set('isRenaming', false);
        this.get('requestTemplate').save();
      },
      clickAction: function clickAction() {
        this.clickAction();
      },
      previewWizard: function previewWizard() {
        this.previewWizardAction();
      },
      openShareModal: function openShareModal() {
        this.openShareModal();
      },
      popoverActionHandler: function popoverActionHandler(action) {
        this.send(action);
      }
    }
  });
});