define('dashboard/components/project-row', ['exports', 'dashboard/mixins/tagging', 'dashboard/mixins/project-actions', 'dashboard/mixins/hover'], function (exports, _tagging, _projectActions, _hover) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tagging.default, _projectActions.default, _hover.default, {
    classNames: ['clickable'],
    tagName: 'tr',

    editing: false,

    lastVisibleTagIndex: null,
    numberOfHiddenTags: 0,

    client: Ember.computed.alias('project.client'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var projectTags = this.get('projectTags').toArray().sortBy('name');
      var element = this.$('.hidden-tag-container');
      var cumulativeWidth = 0,
          lastVisibleTagIndex = projectTags.length - 1,
          i = void 0;

      for (i = 0; i < projectTags.length; i++) {
        var text = projectTags[i].get('name'),
            elementWidth = void 0;
        element.css({ fontSize: 13 });
        element.text(text);
        elementWidth = element.width();
        elementWidth += 38; //extra space within label element
        cumulativeWidth += elementWidth;

        if (cumulativeWidth > 240) {
          lastVisibleTagIndex = i - 1;
          break;
        }
      }
      this.set('lastVisibleTagIndex', lastVisibleTagIndex);
    },


    isProjectRowOptionsHidden: true,

    click: function click() {
      this.send('click');
    },


    actions: {
      save: function save() {
        this.sendAction('updateTitleAction', this.get('project'));
        this.set('editing', false);
      },
      click: function click() {
        this.sendAction('transitionToProject', this.get('project.id'));
      },
      openArchiveProjectModal: function openArchiveProjectModal() {
        this.sendAction('openArchiveProjectModal', this.get('project'));
      },
      openMoveProjectModal: function openMoveProjectModal() {
        this.sendAction('openMoveProjectModal', this.get('project'));
      },
      restoreProject: function restoreProject() {
        this.sendAction('restoreProject', this.get('project'));
      },
      openDeleteProjectModal: function openDeleteProjectModal() {
        this.sendAction('openDeleteProjectModal', this.get('project'));
      },
      addTagToFilterTagsList: function addTagToFilterTagsList(tag) {
        this.sendAction('addTagToFilterTagsList', tag);
      },
      updateProject: function updateProject(property, value) {
        var project = this.get('project');
        project.set(property, value);
        project.save();
      }
    }
  });
});