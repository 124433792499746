define('dashboard/controllers/data-forms/inbox/data-request', ['exports', 'dashboard/mixins/scenario-actions', 'dashboard/mixins/read-only-restrictions', 'dashboard/utils/create-popover-id'], function (exports, _scenarioActions, _readOnlyRestrictions, _createPopoverId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_scenarioActions.default, _readOnlyRestrictions.default, {
    layout: Ember.inject.controller(),

    init: function init() {
      this._super.apply(this, arguments);

      var assignPopoverId = (0, _createPopoverId.default)();
      var assignPopoverOptions = [Ember.Object.create({
        label: 'Create New Project',
        action: 'openCreateProjectModal'
      }), Ember.Object.create({
        label: 'Assign to Existing Project',
        action: 'openMoveProjectModal'
      })];

      this.setProperties({ assignPopoverId: assignPopoverId, assignPopoverOptions: assignPopoverOptions });
    },


    requestTemplate: Ember.computed.alias('scenario.requestTemplate'),
    currentUser: Ember.computed.alias('layout.model'),

    isRevisingDataRequest: false,
    isDataFormSettingsModalHidden: true,
    isMoveProjectModalHidden: true,
    isCreateProjectModalHidden: true,
    isShareModalHidden: true,
    isDataFormOptionsHidden: true,
    isDeleteScenarioModalHidden: true,
    isRestoreScenarioModalHidden: true,

    wizardSections: Ember.computed('scenario.sectionsToDisplay', function () {
      return this.get('scenario.sectionsToDisplay').reject(function (s) {
        return ['welcome', 'thanks', 'reviewAndSubmit'].includes(s.sectionBoolean);
      });
    }),

    // scrollableWizardSections: computed('scenario.sectionsToDisplay', function() {
    //   let summarySection = welcomeSections.summary;
    //   let scenarioSections = this.get('scenario.sectionsToDisplay').reject(s => ['thanks', 'reviewAndSubmit'].includes(s.sectionBoolean));
    //   let basicsIndex = _array.findIndex(scenarioSections, (section => section.sectionBoolean === 'basics')) || 1;
    //   scenarioSections.splice(basicsIndex, 0, summarySection);
    //   return scenarioSections;
    // }),

    // divisions: computed('scenario.divisions', 'summaryDivision', function() {
    //   let summarySection = welcomeSections.summary;
    //   let newDivisions = JSON.parse(JSON.stringify(this.get('scenario.divisions')));
    //   newDivisions.welcome.sectionList.unshift(summarySection);
    //   return newDivisions;
    // }),

    breadcrumbItems: Ember.computed('scenario.{inboxScreen,activeBreadcrumbText,project.title,project.id,client.id}', function () {
      var texts = {
        assigned: 'Assigned',
        inbox: 'Inbox',
        'in-progress': 'In Progress',
        archived: 'Archived'
      };

      var inboxScreen = this.get('scenario.inboxScreen');
      var items = [{
        label: texts[inboxScreen],
        params: ['data-forms.inbox', {
          isQueryParams: true,
          values: {
            selectedTab: inboxScreen
          }
        }]
      }];

      var project = this.get('scenario.project');
      var client = this.get('scenario.client');
      if (inboxScreen === 'assigned' && project.get('title') && client.get('id')) {
        items.push({
          label: this.get('scenario.project.title'),
          params: ['project', client.get('id'), project.get('id')]
        });
      }

      items.push({ label: this.get('scenario.activeBreadcrumbText'), active: true });

      return items;
    }),

    dataFormOptions: Ember.computed('scenario.{inboxScreen,project}', 'inboxScreen', function () {
      var inboxScreen = this.get('scenario.inboxScreen');
      var rowOptions = { title: 'Data Form', options: [] };

      // Print
      rowOptions.options.push({ icon: 'fas fa-print fa-lg', text: 'Print', action: 'print' });

      // Allow Revision
      if (inboxScreen === 'inbox' || inboxScreen === 'assigned') {
        rowOptions.options.push({ icon: 'fa-solid fa-pen-to-square fa-lg', text: 'Allow Revision', action: 'openReviseRequestModal' });
      }

      // Share Data Forn
      if (inboxScreen === 'in-progress') {
        rowOptions.options.push({ icon: 'fas fa-share fa-lg', text: 'Share', action: 'openShareRequestModal' });
      }

      // Go to Project
      // if (this.get('scenario.project.id')) {
      //   rowOptions.options.push({ icon: 'fas fa-arrow-square-right fa-lg', text: 'Go to Project', action: 'goToProject' });
      // }

      // Delete Data Form
      if (!(inboxScreen === 'assigned' || inboxScreen === 'archived')) {
        rowOptions.options.push({ delimiter: true });
        rowOptions.options.push({ icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'showDeleteModal', color: 'red' });
      }

      // Restore Data Form (admin only)
      if (inboxScreen === 'archived') {
        rowOptions.options.push({ delimiter: true });
        rowOptions.options.push({ icon: 'fas fa-trash-undo fa-lg', text: 'Restore', action: 'showRestoreModal', color: 'red' });
      }

      return rowOptions;
    }),

    revisionTitle: Ember.computed('scenario.revisionOfScenario.{bestGuessEmail,submittedOn}', function () {
      var originalScenario = this.get('scenario.revisionOfScenario');
      return originalScenario.get('bestGuessEmail') + ' submitted on ' + moment(originalScenario.get('submittedOn')).format('ll');
    }),

    actions: {
      save: function save() {
        this.get('scenario').save();
      },
      openDataFormSettings: function openDataFormSettings() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isDataFormSettingsModalHidden', false);
      },
      saveScenarioLength: function saveScenarioLength() {
        if (this.checkReadOnly()) {
          return;
        }
        this.get('assumption').save();
        this.set('scenario.dataFormLengthChanged', true);
        this.send('save');
      },
      print: function print() {
        window.print();
      },


      /*
        Handles follow-up actions after creating a new project with a submitted data form
        @param {Object} project the project we just created for the data form
        @param {Object} scenario the data form we're moving
        @param {Boolean} newScenario tells us if we're creating a new scenario with this project
      */
      afterProjectCreate: function afterProjectCreate(project, scenario, newScenario) {
        // push new project into projects array
        this.get('client.projects').pushObject(project);
        this.send('requestMoveFinal', project, scenario, newScenario);
      },
      openCreateProjectModal: function openCreateProjectModal() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isMoveProjectModalHidden', true);
        this.set('isCreateProjectModalHidden', false);
      },
      openMoveProjectModal: function openMoveProjectModal() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isCreateProjectModalHidden', true);
        this.set('isMoveProjectModalHidden', false);
      },
      openShareDataRequestModal: function openShareDataRequestModal() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isRevisingDataRequest', false);
        this.set('isShareModalHidden', false);
      },
      openReviseDataRequestModal: function openReviseDataRequestModal() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isRevisingDataRequest', true);
        this.set('isShareModalHidden', false);
      },
      showDeleteModal: function showDeleteModal(scenario) {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isDeleteScenarioModalHidden', false);
      },
      deleteScenario: function deleteScenario(scenario) {
        var _this = this;

        scenario.set('deletedDataForm', true);
        scenario.save().then(function () {
          _this.transitionToRoute('data-forms.inbox');
        });
      },
      showRestoreModal: function showRestoreModal() {
        if (this.checkReadOnly()) {
          return;
        }
        this.set('isRestoreScenarioModalHidden', false);
      },
      restoreScenario: function restoreScenario(scenario) {
        if (!this.get('currentUser.isSuperAdmin')) {
          return;
        }
        scenario.set('deletedDataForm', false);
        scenario.save();
      },


      // popoverActionHandler(action) {
      //   this.send(action);
      // },

      goToProject: function goToProject(client, project) {
        this.transitionToRoute('project', client, project);
      },
      goToDataRequest: function goToDataRequest(scenario) {
        this.transitionToRoute('data-requests.edit', scenario.get('id'), scenario.get('token'));
      }
    }
  });
});