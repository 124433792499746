define('dashboard/components/fa-menu-popover', ['exports', 'dashboard/mixins/hover', 'dashboard/utils/create-popover-id'], function (exports, _hover, _createPopoverId) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_hover.default, {

    //start temp scroll mixin
    didInsertElement: function didInsertElement() {
      this.bindScrolling();
    },
    willDestroyElement: function willDestroyElement() {
      this.unbindScrolling();
    },
    bindScrolling: function bindScrolling() {
      var _this = this;

      var trackScroll = function trackScroll() {
        return _this.scrolled();
      };
      var onScroll = function onScroll() {
        Ember.run.debounce(_this, trackScroll, 50);
      };

      Ember.$('.inner-content').bind('touchmove', onScroll);
      Ember.$('.inner-content').bind('scroll', onScroll);
    },
    unbindScrolling: function unbindScrolling() {
      Ember.$('.inner-content').unbind('touchmove');
      Ember.$('.inner-content').unbind('scroll');
    },
    scrolled: function scrolled() {
      var innerContent = document.getElementsByClassName('inner-content')[0];
      var offsetTop = Math.floor(innerContent.scrollTop);

      this.set('offsetTop', offsetTop);
    },

    // end temp scroll mixin

    classNameBindings: ['hover:toolTip', 'faSize', 'active'],
    classNames: ['d-inline-flex', 'flex-column', 'align-items-center', 'fa-custom', 'fa-menu-popover'],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('localTargetId', (0, _createPopoverId.default)());
      this.set('offsetTop', 0);
    },


    computedWidth: Ember.computed('width', function () {
      return Ember.String.htmlSafe('width: ' + this.get('width') + 'px');
    }),

    // trying to force menu to close when other menu ellipsis icon is clicked to open
    // mostly a problem on tile views
    mouseDown: function mouseDown(el) {
      var isOverlay = Ember.$(el.target).hasClass('popover-overlay');
      var isIcon = Ember.$(el.target).find('.fa-menu-popover-icon').length;
      var iconCircle = Ember.$(el.target).hasClass('icon-circle');
      var isEllipsis = Ember.$(el.target).hasClass('ellipse-icon');

      var notPopoverMenu = !Ember.$(el.target).find('.popover-menu').length;

      if (notPopoverMenu && (isIcon || isOverlay || iconCircle || isEllipsis)) {
        this.set('isHidden', true);
        el.stopPropagation();
      }
    },
    click: function click(el) {
      this.set('hover', false);

      if (Ember.$(el.target).hasClass('popover-overlay')) {
        this.set('isHidden', true);
      }

      if (!this.get('bubbles')) {
        el.stopPropagation();
      }

      Ember.$('.popover-menu').css('margin-top', -this.get('offsetTop'));
    },


    targetId: Ember.computed('id', function () {
      var customTargetId = this.get('id');
      var localTargetId = this.get('localTargetId');
      return customTargetId || localTargetId;
    }),

    actions: {
      toggleIsHidden: function toggleIsHidden() {
        this.toggleProperty('isHidden');
      },
      sendPopoverAction: function sendPopoverAction(action) {
        this.set('isHidden', true);
        this.sendAction(action, this.get('model'));
      }
    }
  });
});