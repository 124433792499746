define('dashboard/components/tif-row', ['exports', 'dashboard/components/tag-row'], function (exports, _tagRow) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tagRow.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.set('tifRowOptions', {
        title: 'TIF',
        options: [{ icon: 'fas fa-clone fa-lg', text: 'Copy', action: 'openCopyTifModal' }, { delimiter: true }, { icon: 'fas fa-trash fa-lg', text: 'Delete', action: 'openDeleteTifModal', color: 'red' }]
      });
    },


    isTifRowOptionsHidden: true,

    click: function click() {
      this.sendAction('openEditTifDistrictModal');
    },


    actions: {
      openCopyTifModal: function openCopyTifModal() {
        this.sendAction('openCopyTif');
      },
      openDeleteTifModal: function openDeleteTifModal() {
        this.sendAction('openDeleteTif');
      }
    }

  });
});